<template>
  <div @click.stop="cancelActive">
    <admin-title></admin-title>
    <div class="container">
      <div class="page-title">
        <span>特別エントリー管理</span>
      </div>

      <div class="content">
        <div class="left">
          <div class="head">
            <!-- <img @click="changeFlg(0)" v-if="detail.saveFlg == 1" class="head-star" src="../../assets/images/entryLevel/head_star.png">
            <img @click="changeFlg(1)" v-else class="head-star" src="../../assets/images/entryLevel/head_grey.png"> -->
<!--            <img class="head-star" src="../../assets/images/entryLevel/head_star.png">-->
<!--            <img class="head-img" :src="detail.imageUrl">-->

            <img class="head-img" v-if="detail.imageUrl" :src="detail.imageUrl"/>
            <img class="head-img" v-else src='../../assets/images/default_head.png'/>
          </div>

          <div>
            <div class="menu-evt-pc">
              ■最終ログイン<br/>
              {{detail.lastLoginDate}}<br/>
              ■ユーザー情報最終変更<br/>
              {{detail.lastUpdateDate}}
            </div>
          </div>

          <div class="label">
            <span class="label-title">＜ 希望業種 ＞</span>
            <span class="label-desc">{{detail.expectedIndustry}}</span>
          </div>

          <div class="label">
            <span class="label-title">＜ 希望職種 ＞</span>
            <span class="label-desc">{{detail.expectedOccupation}}</span>
          </div>

          <div class="label">
            <span class="label-title">＜ 希望勤務地 ＞</span>
            <span class="label-desc">{{ detail.expectedWorkplace }}</span>
          </div>

          <div class="label">
            <span class="label-title">＜ 希望年収 ＞</span>
            <span class="label-desc">{{ detail.expectedAnnualSalaryMin }}〜{{ detail.expectedAnnualSalaryMax }}万円</span>
          </div>

        </div>
        <div class="right">
          <div class="detail">
            <div class="detail-top">
              <div class="right-top">
                <div class="top-left">
                  <p>エントリー年月日</p>
                  <span>{{detail.entryDate}}</span>
                  <p>エントリー原稿</p>
                  <el-tooltip :visible-arrow="false" class="input-label" effect="dark" :content="detail.entryTitle" placement="top">
                    <span @click="navToUrl" class="ellipsis">{{detail.entryTitle}}</span>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="detail-bottom">
              <div class="bottom-left">
                <!-- <div class="left-button" style="background: #FF8A00;"  v-if="detail.isScout == 1">
                  <img src="../../assets/images/entryLevel/qi.png">
                  <span>スカウト</span>
                  <div class="mask">
                    <div>
                      <p>■送信日時</p>
                      <p>{{detail.entryDate}}</p>
                    </div>
                    <div>
                      <p>■保存リスト名</p>
                      <p>{{detail.saveListName}}</p>
                    </div>
                    <div>
                      <p>■テンプレ名</p>
                      <p>{{detail.tempName}}</p>
                    </div>
                    <div>
                      <p>■セット原稿</p>
                      <p>{{detail.entryTitle}}</p>
                    </div>
                  </div>
                </div>
                <div class="left-button" v-else>
                  <img src="../../assets/images/entryLevel/qi.png">
                  <span>スカウト</span>
                </div> -->
                <p class="left-id">学生ID：{{detail.userId}}</p>
                <p class="left-desc">{{detail.nameRoma}}</p>
                <p class="left-name">{{detail.name}}</p>
              </div>
              <div class="bottom-right">
                <div class="item">
                  <p>生年月日</p>
                  <span>{{detail.birthday}}</span>
                </div>
                <div class="item">
                  <p>性　別</p>
                  <span>{{detail.genderName}}</span>
                </div>
                <div class="item">
                  <p>現住所</p>
                  <span>〒{{detail.postCode}}<br/>{{detail.prefectureName}}{{detail.addressCity}}{{detail.addressStreet}}{{detail.addressBuilding}}</span>
                </div>
                <div class="item">
                  <p>電話番号</p>
                  <span>{{detail.mobile}}</span>
                </div>
                <div class="item">
                  <p>メール</p>
                  <span>{{detail.mail}}</span>
                </div>
                <div class="item">
                  <p>学校名</p>
                  <span>{{detail.schoolName}}</span>
                </div>
                <div class="item">
                  <p>学　部</p>
                  <span>{{detail.faculty}}</span>
                </div>
                <div class="item">
                  <p>学　科</p>
                  <span>{{detail.facultyDepartment}}</span>
                </div>
                <div class="item">
                  <p>コース / 専攻</p>
                  <span>{{detail.courseMajor}}</span>
                </div>
                <div class="item">
                  <p>卒業年度</p>
                  <span>{{detail.graduationYearName}}</span>
                </div>
                <div class="item">
                  <p>学　歴</p>

                  <div v-if="detail.educationList">
                    <span v-for="(item, index) in detail.educationList" :key="index">
                    {{item}}<br v-if="index != detail.educationList.length - 1"/>
                    </span>
                  </div>
                </div>
                <div class="item">
                  <p>免許 / 資格</p>
                  <div v-if="detail.qualificationList">
                    <span v-for="(item, index) in detail.qualificationList" :key="index">
                    {{item}}<br v-if="index != detail.qualificationList.length - 1"/>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <img class="arrow-left" src="../../assets/images/entryLevel/arrow_left.png">
          </div>

          <div class="right-content">
            <div class="content-top">
              <img src="../../assets/images/entryLevel/eye.png">
              <span>一緒に働きたい社長像</span>
            </div>
            <div class="content-title">{{detail.mostImportantName}}</div>
            <div class="content-bottom">{{detail.idealPresidentImage}}</div>

          </div>

          <div class="right-content">
            <div class="content-top">
              <img style="width: 1.484rem" src="../../assets/images/entryLevel/heart.png">
              <span>就職活動で企業選びの軸にしていること</span>
            </div>
            <div class="content-title">{{detail.importanceOfCompName}}</div>
            <div class="content-bottom">
              {{detail.importanceOfCompReason}}
            </div>
          </div>
          <div class="right-content">
            <div class="content-top">
              <img style="width: 1.484rem" src="../../assets/images/entryLevel/zuan.png">
              <span>学生時代に力を入れたこと</span>
            </div>
            <div class="content-title">{{detail.studentEffortName}}</div>
            <div class="content-bottom">
              {{detail.studentEffortReason}}
            </div>
          </div>

          <div class="return-button green-btn" @click="nav()">一覧へ戻る</div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import $ from 'jquery'
export default {
  name: "specialEntryDetail",

  data() {
    return {
      exportActive: false,
      value: '',
      radio: 1,
      value1: '',
      userInfo: {},
      detail: {},
      processId: '',
      statusId: '',
      flowStatusList: [],
      params: '',
      oldStatusId: ''
    }
  },


  mounted() {
    // console.log(this.$route.query.id)
    this.params = this.$route.query.params;

    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
    this.gatDetail();
    this.getFlowNmSelList();

    document.body.scrollTop=document.documentElement.scrollTop=0;


  },

  methods: {



    async gatDetail() {
      // entry/detail
      let res = await this.$axios({url: `/entry/specialDetail`, data: {id: this.$route.query.id}, method: "post"});
      if(res.code == '000') {
        if(res.data.compUserIds) {
          // console.log("ssss")
          res.data.compUserIds = res.data.compUserIds.split(',');
        } else {
          res.data.compUserIds = [];
        }

        // this.detail.formatDate = ;
        // console.log(this.detail.formatDate)
        this.detail = res.data;

        this.processId = this.detail.mainId;
        this.statusId = this.detail.detailId;

        this.oldStatusId = this.detail.detailId;
      }
      this.$forceUpdate();
    },

    nav() {
      this.$router.push({
        path: '/index/specialEntry',
        query: {
          params: this.params
        }
      })
    },

    navToUrl() {
      /*let url = '';
      if(this.detail.entryType == 1) {
        url =  'https://winc-career.jp/meetingDetail?id=' + this.detail.jobHuntingId + '&cid=' + this.detail.compId;
      } else if (this.detail.entryType == 3) {
        url =  'https://winc-career.jp/jobDetail?id=' + this.detail.jobHuntingId + '&cid=' + this.detail.compId;
      }

      window.open(url)*/
    },

    changeExportActive() {
      // console.log("sss")
      this.exportActive = !this.exportActive;
    },

    cancelActive() {
      // console.log("sss1")
      this.exportActive = false;
    },
  }
}
</script>

<style scoped src="../../style/specialEntryDetail.css">

</style>
